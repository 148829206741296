<template>
  <div class="flex_column companyManage">
    <dog-search
      ref="search"
      :config="searchConfig"
      @search="search"
    ></dog-search>
    <dog-table
      ref="dogTable"
      id="024b23dc-896c-4114-ac63-43ddd3d72fe2"
      :columns="tableColumns"
      :service="getList"
    >
    </dog-table>
    <edit-company-dialog
      ref="editCompanyDialog"
      @confirm="searchData"
    ></edit-company-dialog>
    <set-banner-dialog
      ref="setBannerDialog"
      @confirm="searchData"
    ></set-banner-dialog>
    <edit-history-dialog
      ref="editHistoryDialog"
      @confirm="searchData"
    ></edit-history-dialog>
    <set-trend-dialog
      ref="setTrendDialog"
      @confirm="searchData"
    ></set-trend-dialog>
  </div>
</template>

<script>
  import companyService from '@/api/service/company';
  import editCompanyDialog from './editCompanyDialog.vue';
  import setBannerDialog from './setBannerDialog';
  import editHistoryDialog from './editHistoryDialog';
  import setTrendDialog from './setTrendDialog';

  export default {
    name: 'companyManage',
    components: {
      editCompanyDialog,
      setBannerDialog,
      editHistoryDialog,
      setTrendDialog
    },
    data() {
      return {
        searchConfig: [
          {
            type: 'input',
            label: '公司名',
            key: 'name'
          },
          {
            type: 'select',
            label: '类型',
            key: 'type',
            attrs: {
              service: this.$enumService('companyType')
            }
          }
        ],
        tableColumns: [
          {
            prop: 'operate',
            label: '操作',
            width: 300,
            widthGrow: false,
            render: (h, { props: { row } }) => {
              return (
                <permission-element
                  config={[
                    {
                      code: 'editMyCompany',
                      callback: () =>
                        this.$refs.editCompanyDialog.openFrame(row.id)
                    },
                    {
                      code: 'editCompanyHistory',
                      callback: () =>
                        this.$refs.editHistoryDialog.openFrame(row.id)
                    },
                    {
                      code: 'setCompanyTrend',
                      callback: () =>
                        this.$refs.setTrendDialog.openFrame(row.id)
                    },
                    {
                      code: 'setCompanyBanner',
                      callback: () =>
                        this.$refs.setBannerDialog.openFrame(row.id)
                    }
                  ]}></permission-element>
              );
            }
          },
          {
            prop: 'name',
            label: '公司名',
            width: 200,
            showOverflowTooltip: false
          },
          { prop: 'type_cn', label: '类型', width: 50, widthGrow: false },
          { prop: 'contact', label: '联系人', width: 70 },
          { prop: 'phone', label: '联系方式', width: 120 },
          {
            prop: 'address',
            label: '地址',
            width: 200,
            showOverflowTooltip: false
          },
          { prop: 'email', label: '电子邮箱', width: 200 },
          {
            prop: 'mainImg',
            label: '主图',
            width: 200,
            render: (h, { props: { cur } }) =>
              cur &&
              cur.map((item) => (
                <el-image
                  style='height: 30px; margin-right: 10px;'
                  src={item}
                  fit='contain'
                  preview-src-list={cur}
                />
              ))
          },
          {
            prop: 'desc',
            label: '简介',
            width: 400,
            showOverflowTooltip: false,
            render: (h, { props: { row } }) => (
              <div>
                {row.desc &&
                  row.desc.split('\n').map((item) => <div>{item}</div>)}
              </div>
            )
          }
        ]
      };
    },
    mounted() {
      this.searchData();
    },
    methods: {
      searchData() {
        this.$refs.search.search();
      },
      search(query) {
        this.$refs.dogTable.search(query);
      },
      getList(params) {
        return companyService.getOwnCompanyList(params);
      }
    }
  };
</script>

<style lang="scss" scoped></style>
